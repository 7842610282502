import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    InputLabel,
    Hidden,
    AppBar,
    Tabs,
    Tab,
    Button,
    TextField
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select, TabPanel, tabProps, ButtonToolTip } from 'components';
import { MTableToolbar } from 'material-table';
import { DatePicker } from "@material-ui/pickers";
import {
    InfoRounded,
    ArrowBack
} from '@material-ui/icons';

//Services
import { WithTicket, WithSession } from "hoc";
import routesService from "services/routesService";
import lunchRoomsService from "services/lunchRoomsService";
import requisitionDetailsService from "services/requisitionDetailsService";
import requisitionsService from "services/requisitionsService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';

class RoutesCheckList extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        routes: [],
        lunchRooms: [],
        date: Moment(),
        //date: Moment(new Date().toJSON().slice(0, 10)),
        routeId: null,
        lunchRoom: null,
        // date: Moment('01/28/2020', "MM/DD/YYYY").startOf('day').toDate(),
        // routeId: 6,
        // lunchRoom: { lunchRoomName: "FAMA", lunchRoomId: 8 },
        tab: 0,
        plasticBoxes: "",
        comments: ""
    };

    columns = [
        {
            title: "Unidad",
            field: 'lunchRoomName',
        },
        {
            title: "Estado",
            field: 'status'
        },
        {
            title: " ",
            field: ' ',
            render: (row) => {
                if (row.status != "Por Validar")
                    return null;

                return (
                    <Button color="primary" onClick={() => this.setState({ lunchRoom: row })}>Validar</Button>
                );
            }
        }
    ];

    detailColumns = [
        {
            title: "Almacen",
            field: 'storageName',
            customSort: (a, b) => {
                var first = a.storageName + a.itemPresentationDescription;
                var second = b.storageName + b.itemPresentationDescription;
                if (first < second) { return -1; }
                if (first > second) { return 1; }
                return 0;
            }
        },
        {
            title: "Articulo",
            field: 'itemPresentationDescription',
            customSort: (a, b) => {
                var first = a.storageName + a.itemPresentationDescription;
                var second = b.storageName + b.itemPresentationDescription;
                if (first < second) { return -1; }
                if (first > second) { return 1; }
                return 0;
            }
        },
        {
            title: "Surtido",
            field: 'suppliedQuantity',
            type: 'numeric'
        },
        {
            title: "U/M",
            field: 'measurementUnitName'
        },
        {
            title: "Cant. embarcada",
            render: row => this.renderRowInput(row)
        },
        {
            title: "Diferencia",
            field: 'difference',
        },
    ];

    componentDidMount = () => {
        this.getData();
        this.search();

        if (this.state.lunchRoom)
            this.getLunchRoomCheckList();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            date,
            routeId,
        } = this.state;

        if (prevState.date != date || prevState.routeId != routeId)
            this.search();

        if (!prevState.lunchRoom && this.state.lunchRoom)
            this.getLunchRoomCheckList();
    }

    getData = async () => {
        const routesResponse = await routesService.getAll();
        if (routesResponse.ok && routesResponse.data.status != "Fail") {
            const routes = routesResponse.data.data.map(i => ({ value: i.routeId, label: i.name }));
            this.setState({ routes });
        }
    }

    getLunchRoomCheckList = async () => {
        const {
            lunchRoom,
            date
        } = this.state;

        const response = await requisitionDetailsService.getRouteLunchRoomDetails(lunchRoom.lunchRoomId, date.startOf('day').toDate().toISOString().slice(0, 10));

        if (!response.ok) {
            this.props.addNotification({ message: response.data.data.error, options: { variant: "error" } });
            return;
        }

        this.setState({
            details: response.data.data.map((i, index) => ({ ...i, index, shipped: '', diference: '' }))
        });
    }

    renderRowInput = (row) => {
        return (
            <TextField
                inputRef={i => {
                    if (!row.suppliedQuantity)
                        return;

                    this[`input${row.index}`] = i;
                }}
                type="text"
                value={row.shipped}
                disabled={!row.suppliedQuantity}
                onChange={(e) => {
                    const embarcado = e.target.value;

                    if (isNaN(embarcado))
                        return;

                    const details = this.state.details;
                    const detail = details.find(x => x.supplierRequisitionDetailId == row.supplierRequisitionDetailId);

                    detail.shipped = embarcado;
                    detail.difference = (embarcado || 0) - (detail.suppliedQuantity || 0);
                    this.setState({ details });
                }}
                onKeyPress={async (e) => {
                    if (e.key == "Enter") {
                        e.preventDefault();
                        this.nextFocus(row.index);
                    }
                }}
                onKeyDown={e => {
                    if (e.keyCode == 38) { //Up
                        e.preventDefault();
                        this.previousFocus(row.index);
                    }
                    else if (e.keyCode == 40) { //Down
                        e.preventDefault();
                        this.nextFocus(row.index);
                    }
                }}
            />
        );
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    search = async () => {
        const {
            date,
            routeId
        } = this.state;

        const response = await lunchRoomsService.search(routeId, date.startOf('day').toDate().toISOString().slice(0, 10));
        if (response.ok && Array.isArray(response.data.data)) {
            this.setState({ lunchRooms: response.data.data });
        }
    }

    getTitle = () => {

        return "Checklist de Chofer";
    }

    validateLunchRoom = async () => {
        const {
            plasticBoxes,
            details,
            comments
        } = this.state;

        if (plasticBoxes == "" || isNaN(plasticBoxes)) {
            this.props.addNotification({ message: "Número de cajas plasticas requerido", options: { variant: "error" } });
            return;
        }

        if (this.saving)
            return;

        this.saving = true;
        this.setState({ saving: true });

        const model = {
            plasticBoxes,
            comments,
            supplierRequisitionItems: details.map(i => ({
                SupplierRequisitionDetailId: i.supplierRequisitionDetailId,
                difference: (i.shipped || 0) - (i.suppliedQuantity || 0)
            }))
        };

        const response = await requisitionsService.postDriverCheckList(model).catch(() => {
            this.saving = false;
            this.setState({ saving: false });
        });

        this.saving = false;
        this.setState({ saving: false });

        if (!response.ok || !response.data.data) {
            this.props.addNotification({ message: "Error validando unidad", options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: "Unidad validada", options: { variant: "success" } });
        const lunchRooms = this.state.lunchRooms;
        const lunchRoom = lunchRooms.find(x => x.lunchRoomId == this.state.lunchRoom.lunchRoomId);
        lunchRoom.status = "Validado";
        this.setState({ lunchRoom: null, plasticBoxes: "", details: [], lunchRooms });
    }

    renderFilters = () => {
        const {
            date,
            routeId,
            routes
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={date}
                            onChange={date => this.setState({ date })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Ruta</InputLabel>
                        <Select
                            value={routeId}
                            onChange={(selected) => {
                                this.setState({ routeId: selected.value });
                            }}
                            options={routes}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderDetailsTable = () => {
        const {
            details
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        columns={this.detailColumns}
                        items={details}
                        actions={[
                            {
                                icon: 'content_copy',
                                tooltip: 'Copiar cantidades surtidas',
                                onClick: this.copyQuantities,
                                isFreeAction: true,
                            }
                        ]}
                        options={{
                            paging: false,
                            search: false
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    copyQuantities = () => {
        const details = this.state.details.map(i => ({ ...i, shipped: i.suppliedQuantity, difference: 0 }));
        this.setState({ details });
    }

    renderTable = () => {
        const {
            lunchRooms,
            selectedItems,
            copiyng
        } = this.state;

        let items = lunchRooms;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            // selection: copiyng ? false : true
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        components={{
                            Toolbar: props => (
                                <div>
                                    {this.renderFilters()}
                                    <MTableToolbar {...props} />
                                </div>
                            ),
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderGeneralInfo = () => {
        const {
            classes
        } = this.props;

        const {
            lunchRoom,
            isLoading,
            date,
            plasticBoxes,
            comments
        } = this.state;

        return (
            <React.Fragment>

                <Grid container justify="center" className={classes.root} spacing={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={2}>
                            <TextField
                                value={lunchRoom.lunchRoomName}
                                disabled
                                label={"Unidad"}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={2}>
                            <TextField
                                disabled
                                value={Moment(date).format("DD/MM/YYYY")}
                                label={"Fecha"}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                value={comments}
                                name="checkListComment"
                                label={"Comentarios"}
                                multiline
                                rows={2}
                                type="text"
                                margin="normal"
                                inputProps={{
                                    maxLength: 200
                                }}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => {
                                    this.setState({ comments: e.target.value });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={2}>
                            <TextField
                                value={plasticBoxes}
                                name="displayName"
                                label={"Cantidad de cajas"}
                                type="number"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => {
                                    this.setState({ plasticBoxes: e.target.value });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                                onClick={this.validateLunchRoom}
                            >
                                Validar
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {this.renderDetailsTable()}
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    renderConfirm = () => {
        const { classes } = this.props;
        const { tab } = this.state;

        return (
            <div>
                <ButtonToolTip
                    className={classes.backButton}
                    onClick={() => {
                        this.setState({ lunchRoom: null, plasticBoxes: "", details: [] });
                    }}
                    title="Regresar"
                >
                    <ArrowBack />
                </ButtonToolTip>
                <AppBar
                    position="static"
                    color="default"
                >
                    <Tabs
                        value={tab}
                        onChange={(e, tab) => {
                            this.setState({ tab });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab
                            icon={<InfoRounded />}
                            label={<Hidden xsDown>Checklist de Unidad</Hidden>}
                            {...tabProps(0)}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0}>
                    {
                        this.renderGeneralInfo()
                    }
                </TabPanel>
            </div>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading,
            lunchRoom
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : (
                                            lunchRoom
                                                ? this.renderConfirm()
                                                : this.renderTable()
                                        )
                                }
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithSession(WithNotifications(WithTicket(withStyles(styles)(RoutesCheckList))))));