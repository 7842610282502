import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import SupplierStoragesView from "views/SupplierStorages";

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    Checkbox,
    IconButton,
} from '@material-ui/core';

import { Visibility } from '@material-ui/icons';

import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Modal } from 'components';

//Services
import suppliersService from "services/suppliersService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';

class Suppliers extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        items: [],
        date: Moment().startOf('day').toDate(),
        tab: 0,
        plasticBoxes: "",
        details: [],
        showStoragesOfSupplier: null,
    };

    columns = [
        {
            title: "ID",
            field: "supplierId",
            editable: 'never',
        },
        {
            title: "Nombre",
            field: 'supplierName'
        },
        {
            title: "Alias",
            field: 'alias'
        },
        {
            title: "Descripcion",
            field: 'supplierDescription',
        },
        {
            title: "RFC",
            field: 'rfc'
        },
        {
            title: "Dias de crédito",
            field: 'creditDays'
        },
        {
            title: "Dirección",
            field: 'address'
        },
        {
            title: "Tel. Contacto",
            field: 'contactPhoneNumber'
        },
        {
            title: "Código SAP",
            field: 'sapCode'
        },
        {
            title: "Almacenes",
            field: "supplierId",
            editable: 'never',
            render: row => (
                <IconButton
                    disabled={!row || !row.supplierId}
                    onClick={() => {
                        this.setState({ showStoragesOfSupplier: row.supplierId });
                    }}
                >
                    <Visibility />
                </IconButton>
            )
        },
        {
            title: "Activo",
            field: 'isActive',
            type: 'bool',
            initialEditValue: true,
            editComponent: props => (
                <Checkbox
                    checked={props.value}
                    onChange={(e, value) => {
                        props.onChange(value);
                    }}
                />
            ),
            render: row => (
                <Checkbox
                    disabled
                    checked={row.isActive}
                // onChange={(e, value) => {
                //     const items = this.state.items;
                //     const item = items.find(x => x.row.tableData.id);
                //     item.isActive = value;

                //     this.setState({ items });
                // }}
                />
            )
        },
    ];

    componentDidMount = () => {
        this.getData();
    }

    getData = async () => {
        const response = await suppliersService.getAll();
        if (response.ok && response.data.status != "Fail") {
            this.setState({ items: response.data.data });
        }
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    getTitle = () => {
        return "Proveedores";
    }

    save = async (newData) => {
        if (!newData.supplierName || !newData.alias || !newData.supplierDescription || !newData.rfc || !newData.address || !newData.contactPhoneNumber || !newData.sapCode) {
            this.props.addNotification({ message: 'Todos los datos son requeridos', options: { variant: "error" } });
            return Promise.reject();
        }

        const response = await suppliersService.save({ ...newData, creditDays: newData.creditDays || 0 });

        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
        this.getData();
    }

    update = async (newData) => {
        const response = await suppliersService.save(newData);

        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
        this.getData();
    }

    delete = async (item) => {
        const response = await suppliersService.delete(item.supplierId);

        if (response.data.status == "Fail") {
            this.props.addNotification({ message: response.data.data, options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: 'Proveedor borrado exitosamente', options: { variant: "success" } });
        this.getData();
    }

    renderTable = () => {
        const {
            items,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        isLoading={loading}
                        columns={this.columns}
                        items={items}
                        options={{
                            pageSize: 10,
                            search: false
                            // selection: copiyng ? false : true
                        }}
                        editable={{
                            onRowAdd: this.save,
                            onRowUpdate: this.update,
                            onRowDelete: this.delete,
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderStoragesModal = () => {
        const {
            showStoragesOfSupplier,
        } = this.state;

        return (
            <Modal
                title="Almacenes de proveedor"
                isOpen={showStoragesOfSupplier != null}
                onRequestClose={() => {
                    this.setState({ showStoragesOfSupplier: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <SupplierStoragesView
                    id={showStoragesOfSupplier}
                    hideLayout
                    onClose={() => this.setState({ showStoragesOfSupplier: null })}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                        {this.renderStoragesModal()}
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default WithNotifications(withStyles(styles)(Suppliers));