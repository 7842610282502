import Api from "./Api";

class SuppliersService extends Api {
	constructor(controller) {
		super(controller);
	}

	save = model => {
		return this.API.post(`${this.controller}`, model);
	};

	getBranches = () => {
		return this.API.get(`${this.controller}/branches`);
	};
}

let service = new SuppliersService("suppliers");

export default service;