import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Swal from 'sweetalert2';

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    InputLabel,
    Button,
    //Checkbox,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core';

import Modal from 'components/Modal';
import ViewRequisition from "views/ViewRequisition";

import { Dashboard as DashboardLayout } from 'layouts';
import {
    Portlet,
    PortletContent,
    Table,
    Select,
} from 'components';
import { MTableToolbar } from 'material-table';
import { DatePicker } from "@material-ui/pickers";
import {
    // SendOutlined,
    CheckCircleOutlined,
    AdjustOutlined,
    HighlightOffOutlined,
    AccessTimeOutlined,
    DescriptionOutlined,
    PictureAsPdfOutlined,
    SendOutlined,
    AddCircleOutlined,
} from '@material-ui/icons';

//Services
import { WithTicket, WithSession } from "hoc";
import routesService from "services/routesService";
import driversService from "services/driversService";
import vehiclesService from "services/vehiclesService";
import requisitionService from "services/requisitionsService";
import deliveriesService from "services/deliveriesService";
import cfdiService from "services/cfdiService";
import lunchRoomsService from "services/lunchRoomsService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';

class Deliveries extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        routes: [],
        drivers: [],
        vehicles: [],
        units: [],
        date: Moment.utc().local().startOf('day').toDate(),
        routeId: null,
        driverId: null,
        vehicleId: null,
        tab: 0,
        delivery: null,
        details: [],
        signing: false,
        showUnitsModal: false,
    };

    columns = [
        {
            title: "Unidad Destino",
            field: 'description'
        },
        {
            title: "Folio Requisición",
            field: 'requisition.folio',
            render: (row) => (
                <Link
                    href="#"
                    onClick={e => {
                        e.preventDefault();
                        this.setState({ requisitionIdToShow: row.requisitionId });
                    }}
                >
                    {row.requisition.folio}
                </Link>
            )
        },
    ];

    unitsColumns = [
        {
            title: "#",
            field: 'lunchRoomId'
        },
        {
            title: "Unidad",
            field: 'lunchRoomName'
        },
    ];

    detailColumns = [
        {
            title: "Almacen",
            field: 'storageName'
        },
        {
            title: "Articulo",
            field: 'itemPresentationDescription'
        },
        {
            title: "Cantidad",
            field: 'quantity',
            type: 'numeric'
        },
        {
            title: "U/M",
            field: 'measurementUnitName'
        }
    ];

    componentDidMount = () => {
        this.getData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            routeId,
            vehicleId,
            driverId
        } = this.state;

        const {
            routeId: prevRouteId,
            vehicleId: prevVehicleId,
            driverId: prevDriverId,
        } = prevState;

        if (routeId != prevRouteId || vehicleId != prevVehicleId || driverId != prevDriverId) {
            this.setState({ delivery: null, details: [] });
        }
    }

    getData = async () => {
        const routesPromise = routesService.getAll();
        const vehiclesPromise = vehiclesService.getAll();
        const driversPromise = driversService.getAll();

        let [
            routesResponse,
            vehiclesResponse,
            driversResponse,
        ] = await Promise.all([
            routesPromise,
            vehiclesPromise,
            driversPromise,
        ]);

        if (routesResponse.ok && routesResponse.data.status != "Fail") {
            const routes = routesResponse.data.data.filter(x => x.generateConsignmentNote).map(i => ({ value: i.routeId, label: i.name }));
            this.setState({ routes: [{ value: 0, label: "Ruta temporal" }, ...routes], originalRoutes: routesResponse.data.data });
        }

        if (vehiclesResponse.ok && vehiclesResponse.data.status != "Fail") {
            const vehicles = vehiclesResponse.data.data.map(i => ({ value: i.id, label: `${i.brand} ${i.model} ${i.year} | Placas: ${i.plate} | #${i.transportCode}` }));
            this.setState({ vehicles, originalVehicles: vehiclesResponse.data.data });
        }

        if (driversResponse.ok && driversResponse.data.status != "Fail") {
            const drivers = driversResponse.data.data.map(i => ({ value: i.employeeId, label: `${i.name} ${i.lastName1} ${i.lastName2}` }));
            this.setState({ drivers, originalDrivers: driversResponse.data.data });
        }
    }

    getStatusName = (signStatus) => {
        var statuses = {
            "0": "Pendiente",
            "1": "Generando",
            "2": "Con error",
            "3": "Otro",
            "10": "Generado",
            "99": "Cancelado",
        };

        return (
            <span style={{ marginLeft: 5 }}>
                {statuses[signStatus]}
            </span>
        );
    }

    download = (type) => () => {
        alert(type);
    }

    getStatusIcon = (signStatus) => {
        var statuses = {
            "0": <AccessTimeOutlined />,
            "1": <CircularProgress disableShrink />,
            "2": <HighlightOffOutlined />,
            "3": <AdjustOutlined />,
            "10": <CheckCircleOutlined />,
            "99": <HighlightOffOutlined />,
        };

        return statuses[signStatus];
    }

    getLunchRooms = async () => {
        const {
            date,
        } = this.state;

        const response = await lunchRoomsService.getLocalUnits(date);

        if (!response.ok) {
            this.props.addNotification({ message: response.data.data.error, options: { variant: "error" } });
            return;
        }

        this.setState({
            units: response.data.data.map((i, index) => ({ ...i, index }))
        });
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    search = async () => {
        return new Promise(resolve => {
            const {
                routeId,
                vehicleId,
                driverId,
                originalDrivers,
                date,
            } = this.state;

            const employee = originalDrivers.find(x => x.employeeId == driverId);
            if (!employee || !employee.driverInfo)
                return;

            const correctDriverId = employee.driverInfo.driverId;

            this.setState({ loading: true });

            const date2 = Moment.utc(date).local();

            requisitionService.getUnitsWithRquisitionComplete(routeId, vehicleId, correctDriverId, date2).then(response => {
                if (response.ok && response.data.data && response.data.data.deliveryDetails && Array.isArray(response.data.data.deliveryDetails)) {
                    const delivery = response.data.data;

                    if (delivery.signDate) {
                        delivery.originalSignDate = new Date(delivery.signDate);
                        delivery.signDate = new Date(delivery.signDate);
                    }

                    this.setState({ delivery, details: response.data.data.deliveryDetails.map(i => ({ ...i, generate: i.signStatus == 0 })) }, () => {
                        window.setTimeout(() => resolve(true), 200);
                    });
                }

                this.setState({ loading: false });
            });

            this.getLunchRooms();
        });
    }

    getTitle = () => {
        return "Carta Porte";
    }

    renderVehicle = () => {
        const {
            vehicleId: id,
            originalVehicles: items,
        } = this.state;

        const {
            classes
        } = this.props;

        const i = items.find(x => x.id == id);
        if (!i)
            return null;

        return (
            <Grid
                item xs={12} md={4} style={{ padding: 10 }}
            >
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Vehiculo
                        </Typography>
                        {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Vehiculo
                        </Typography> */}
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Marca:</span>
                                <b>{i.brand}</b>
                            </div>
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Modelo:</span>
                                <b>{i.model}</b>
                            </div>
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Año: </span>
                                <b>{i.year}</b>
                            </div>
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Placas: </span>
                                <b>{i.plates}</b>
                            </div>
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>S/N: </span>
                                <b>{i.serialNumber}</b>
                            </div>
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span># Camión: </span>
                                <b>{i.transportCode}</b>
                            </div>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    renderDriver = () => {
        const {
            driverId: id,
            originalDrivers: items,
        } = this.state;

        const {
            classes
        } = this.props;

        const i = items.find(x => x.employeeId == id);
        if (!i)
            return null;

        return (
            <Grid
                item xs={12} md={4} style={{ padding: 10 }}
            >
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        {
                            !i.driverInfo &&
                            <Typography variant="body2" component="p">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <b>Empleado sin información de chofer, dar de alta en Alimentro</b>
                                </div>
                            </Typography>
                        }
                        {
                            i.driverInfo &&
                            <div>

                                <Typography gutterBottom variant="h5" component="h2">
                                    Chofer {!i.driverInfo && " | Inválido"}
                                </Typography>
                                <Typography variant="body2" component="p">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Nombre:</span>
                                        <b>{i.name} {i.lastName1} {i.lastName2}</b>
                                    </div>
                                </Typography>
                                <Typography variant="body2" component="p">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Licencia:</span>
                                        <b>{i.driverInfo.licenseNumber}</b>
                                    </div>
                                </Typography>
                                <Typography variant="body2" component="p">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Expedición:</span>
                                        <b>{Moment(i.driverInfo.licenseRegistrationDate).format("DD/MM/YYYY")}</b>
                                    </div>
                                </Typography>
                                <Typography variant="body2" component="p">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span>Expira:</span>
                                        <b>{Moment(i.driverInfo.licenseExpirationDate).format("DD/MM/YYYY")}</b>
                                    </div>
                                </Typography>
                            </div>
                        }
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    renderRoute = () => {
        const {
            routeId: id,
            originalRoutes: items,
        } = this.state;

        const {
            classes
        } = this.props;

        const i = items.find(x => x.routeId == id);
        if (!i)
            return null;

        return (
            <Grid
                item xs={12} md={4} style={{ padding: 10 }}
            >
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Ruta
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Nombre:</span>
                                <b>{i.name}</b>
                            </div>
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>CFDI Traslado:</span>
                                <b>Si</b>
                            </div>
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Carta porte:</span>
                                <b>{i.generateConsignmentNote ? "Si" : "No"}</b>
                            </div>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    renderSigning = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    padding: 20,
                }}
            >
                <CircularProgress />
                <Typography style={{ marginLeft: 12 }}>Timbrando...</Typography>
            </div>
        );
    }

    renderCfdiInfo = () => {
        const {
            delivery,
            signing,
            routeId,
            originalRoutes,
            details,
        } = this.state;

        const {
            classes
        } = this.props;

        if (delivery == null)
            return null;

        if (signing)
            return this.renderSigning();

        const signed = delivery.cfdi && delivery.cfdi.uuid;
        const canBeSigned = details.length > 0;

        let route = null;
        if (!delivery.isTemp) {
            route = originalRoutes.find(x => x.routeId == routeId);
        }

        return (
            <Grid
                item xs={12} style={{ padding: 10 }}
            >
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Información de timbrado de traslado
                        </Typography>
                        <Typography variant="body2" component="p">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>Timbrado:</span>
                                <b>{(signed ? "Si" : "No")}</b>
                                {
                                    !delivery.isTemp && route && route.specifySignDate && !signed &&
                                    <Grid
                                        direction="column"
                                        justify="center"
                                        className={
                                            classNames(
                                                classes.inputRoot
                                            )
                                        }
                                    >
                                        <InputLabel>Fecha timbrado</InputLabel>
                                        <DatePicker
                                            format="DD MMMM [del] YYYY"
                                            value={delivery.signDate}
                                            onChange={v => this.setState(ps => ({ delivery: { ...ps.delivery, signDate: v } }))}
                                            autoOk
                                        />
                                    </Grid>
                                }
                                {
                                    !signed && canBeSigned &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => {
                                            if (!delivery.id) {
                                                const generated = await this.generate();

                                                if (generated)
                                                    this.sign();
                                            }
                                            else {
                                                if (delivery.originalSignDate != delivery.signDate) {
                                                    const updated = await this.updateDelivery(delivery.signDate);

                                                    if (updated)
                                                        this.sign();
                                                }
                                                else {
                                                    this.sign();
                                                }
                                            }
                                        }}
                                        startIcon={<SendOutlined />}
                                    >
                                        {delivery.isTemp ? "Crear/Timbrar" : "Timbrar"}
                                    </Button>
                                }
                            </div>
                        </Typography>
                        {
                            canBeSigned && !signed && delivery.cfdi && delivery.cfdi.incidencias &&
                            <Typography variant="body2" component="p">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Incidencias:</span>
                                    <p><b>{delivery.cfdi.incidencias}</b></p>
                                </div>
                            </Typography>
                        }
                        {
                            signed &&
                            <Typography variant="body2" component="p">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Fecha timbrado:</span>
                                    <p><b>{Moment(delivery.cfdi.stampDate).format("DD/MM/YYYY HH:mm:ss")}</b></p>
                                </div>
                            </Typography>
                        }
                        {
                            signed &&
                            <Typography variant="body2" component="p">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>UUID:</span>
                                    <p><b>{delivery.cfdi.uuid}</b></p>
                                </div>
                            </Typography>
                        }
                        {
                            signed &&
                            <Typography variant="body2" component="p">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Opciones de descarga</span>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to={{ pathname: `${window.env.API_URL}reports/cfdi-delivery?deliveryId=${delivery.id}` }}
                                            style={{ margin: 10 }}
                                            target="_blank"
                                            download
                                            startIcon={<PictureAsPdfOutlined />}
                                        >
                                            PDF
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to={{ pathname: `${window.env.API_URL}cfdi/${delivery.cfdiId}/xml` }}
                                            style={{ margin: 10 }}
                                            target="_blank"
                                            download
                                            startIcon={<DescriptionOutlined />}
                                        >
                                            XML
                                        </Button>
                                        {/* <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.download(3)}
                                            style={{ margin: 10 }}
                                        >
                                            ZIP
                                        </Button> */}
                                    </div>
                                </div>
                            </Typography>
                        }
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    renderFilters = () => {
        const {
            date,
            routeId,
            routes,
            driverId,
            drivers,
            vehicleId,
            vehicles,
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid
                    container
                    xs={12}
                >
                    <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                        <Grid
                            direction="column"
                            justify="center"
                            className={
                                classNames(
                                    classes.inputRoot
                                )
                            }
                        >
                            <InputLabel>Fecha</InputLabel>
                            <DatePicker
                                format="DD MMMM [del] YYYY"
                                value={date}
                                onChange={date => this.setState({ date })}
                                autoOk
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                        <Grid
                            direction="column"
                            justify="center"
                            className={
                                classNames(
                                    classes.inputRoot
                                )
                            }
                        >

                            <InputLabel>Camión</InputLabel>
                            <Select
                                value={vehicleId}
                                onChange={(selected) => {
                                    this.setState({ vehicleId: selected.value });
                                }}
                                options={vehicles}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                                menuPosition={'absolute'}
                                menuPlacement={'bottom'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                        <InputLabel>Chofer</InputLabel>
                        <Select
                            value={driverId}
                            onChange={(selected) => {
                                this.setState({ driverId: selected.value });
                            }}
                            options={drivers}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item}>
                        <InputLabel>Ruta</InputLabel>
                        <Select
                            value={routeId}
                            onChange={(selected) => {
                                this.setState({ routeId: selected.value });
                            }}
                            options={routes}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                    {
                        routeId > 0 &&
                        <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item} style={{ alignSelf: 'self-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.search}
                            >
                                Buscar
                            </Button>
                        </Grid>
                    }
                    {
                        routeId == 0 &&
                        <Grid item xs={12} md={3} lg={2} alignContent="center" className={classes.item} style={{ alignSelf: 'self-end' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.search}
                            >
                                Crear ruta temporal
                            </Button>
                        </Grid>
                    }
                </Grid>
                <Grid
                    container
                    xs={12}
                >
                    {vehicleId && this.renderVehicle()}
                    {driverId && this.renderDriver()}
                    {routeId != null && routeId > 0 && this.renderRoute()}
                </Grid>
                <Grid
                    container
                    xs={12}
                >
                    {this.renderCfdiInfo()}
                </Grid>
            </Grid>
        );
    }

    openAddItemModal = () => {

        this.setState({ showItemsModal: true });
    }

    deleteItem = (row) => {
        let details = this.state.details;
        details = details.filter(x => x.itemPresentationId != row.itemPresentationId);
        this.setState({ details });
    }

    addItem = () => {
        const {
            item,
            quantity
        } = this.state;

        let details = this.state.details;
        details.push({
            storageName: item.storageName,
            itemPresentationDescription: item.label,
            quantity: quantity,
            itemPresentationId: item.value,
            measurementUnitName: item.measurementUnitName
        });

        this.setState({ details, showItemsModal: false, quantity: "", itemId: null, item: null });
    }

    generate = () => {
        return new Promise(resolve => {
            const {
                driverId,
                routeId,
                vehicleId,
                originalDrivers,
                originalVehicles,
                originalRoutes,
                delivery,
                details,
            } = this.state;

            const employee = originalDrivers.find(x => x.employeeId == driverId);
            if (!employee || !employee.driverInfo) {
                this.props.addNotification({ message: "Falta información de chofer en Alimentro", options: { variant: "warning" } });
                resolve(false);
                return;
            }

            let route = null;
            if (!delivery.isTemp) {
                route = originalRoutes.find(x => x.routeId == routeId);
                if (!route) {
                    this.props.addNotification({ message: "No se seleccionó ruta", options: { variant: "warning" } });
                    resolve(false);
                    return;
                }
            }

            const vehicle = originalVehicles.find(x => x.id == vehicleId);
            if (!vehicle) {
                this.props.addNotification({ message: "No se seleccionó camión", options: { variant: "warning" } });
                resolve(false);
                return;
            }

            this.setState({ loading: true });

            const model = { ...delivery, deliveryDetails: details };
            if (model.signDate) {
                model.signDate = Moment.utc(model.signDate).local().startOf('day').toDate();
            }

            deliveriesService.save(model).then(response => {
                if (response.ok && response.data.data && response.data.data) {
                    this.props.addNotification({ message: "Traslado creado correctamente", options: { variant: "success" } });
                    this.search().then(() => {
                        resolve(true);
                        return;
                    });

                    return;
                }

                this.setState({ loading: false });
                resolve(false);
            });
        });

    }

    updateDelivery = () => {
        return new Promise(resolve => {
            const {
                delivery,
            } = this.state;

            this.setState({ loading: true });

            let date = delivery.signDate;
            if (date) {
                date = Moment.utc(date).local().startOf('day').toDate();
            }

            deliveriesService.updateDelivery(delivery.id, date).then(response => {
                if (response.ok && response.data.data && response.data.data) {
                    this.props.addNotification({ message: "Fecha de traslado actualizada correctamente", options: { variant: "success" } });
                    resolve(true);
                    return;
                }

                this.setState({ loading: false });
                resolve(false);
            });
        });
    }

    sign = async () => {
        try {
            const {
                delivery,
            } = this.state;

            if (!delivery || !delivery.id) {
                return;
            }

            this.setState({ signing: true });
            const response = await cfdiService.signDelivery(delivery.id);
            if (response && response.ok) {
                this.search();
            }
            else {
                if (!response.data) {
                    this.props.addNotification({ message: "Sucedió un error timbrando el traslado", options: { variant: "error" } });
                }
                else {
                    // Swal.fire(
                    //     'Error al timbrar',
                    //     response.data,
                    //     'warning'
                    // );
                    Swal.fire({
                        title: "Error al timbrar",
                        html: response.data,
                        type: 'warning',
                        width: '1000px'
                    });
                }
            }

            this.setState({ signing: false });
        }
        catch (e) {
            this.setState({ signing: false });
        }
    }

    addUnitToTempRoute = async (unit) => {
        const {
            date,
            delivery,
        } = this.state;

        console.log(unit);

        this.setState({ addingUnit: true });

        if (delivery.deliveryDetails.filter(x => x.lunchRoomId == unit.lunchRoomId).length > 0) {
            this.props.addNotification({ message: "La unidad ya esta agregada a la ruta", options: { variant: "error" } });
            this.setState({ addingUnit: false });
            return;
        }

        try {
            const response = await requisitionService.getDailyRequisitionsByLunchRoom(unit.lunchRoomId, date);

            console.log(response);

            if (!response.ok) {
                this.props.addNotification({ message: response.data.data.error, options: { variant: "error" } });
                this.setState({ addingUnit: false });
                return;
            }

            const newDetails = response.data.data.map(i => ({
                requisitionId: i.supplierRequisitionId,
                lunchRoomId: unit.lunchRoomId,
                description: unit.lunchRoomName,
                requisition: {
                    folio: i.folio,
                    requisitionId: i.supplierRequisitionId,
                },
            }));

            this.setState(ps => ({
                details: [
                    ...ps.details,
                    ...newDetails,
                ],
            }));
        }
        catch (e) {
            console.log(e);
        }

        this.setState({ addingUnit: false });
    }

    renderTable = () => {
        const {
            loading,
            details,
            delivery,
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        title="Unidades"
                        isLoading={loading}
                        columns={this.columns}
                        items={details}
                        options={{
                            paging: false,
                            search: false,
                        }}
                        actions={
                            delivery && delivery.isTemp
                                ? [
                                    {
                                        icon: () => <AddCircleOutlined />,
                                        isFreeAction: true,
                                        tooltip: "Agregar unidades",
                                        onClick: () => this.setState({ showUnitsModal: true }),
                                    }
                                ]
                                : []
                        }
                        components={{
                            Toolbar: props => (
                                <div>
                                    {this.renderFilters()}
                                    <MTableToolbar {...props} />
                                </div>
                            ),
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderUnitsModal = () => {
        const {
            showUnitsModal,
            units,
        } = this.state;

        return (
            <Modal
                title="Unidades con requisiciones"
                isOpen={showUnitsModal}
                onRequestClose={() => {
                    this.setState({ showUnitsModal: false });
                }}
                style={{ width: '500px', height: '80%' }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Table
                            columns={this.unitsColumns}
                            items={units}
                            actions={[
                                {
                                    icon: () => <AddCircleOutlined />,
                                    tooltip: "Agregar unidad la ruta",
                                    onClick: (e, row) => this.addUnitToTempRoute(row),
                                }
                            ]}
                            options={{
                                paging: false,
                                search: false,
                                toolbar: false,
                            }}
                        />
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderViewModal = () => {
        const {
            requisitionIdToShow
        } = this.state;

        return (
            <Modal
                title="Detalle de requisición"
                isOpen={this.state.requisitionIdToShow != null}
                onRequestClose={() => {
                    this.setState({ requisitionIdToShow: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <ViewRequisition
                    id={requisitionIdToShow}
                    hideLayout
                    onClose={() => this.setState({ requisitionIdToShow: null })}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading,
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                        {this.renderViewModal()}
                        {this.renderUnitsModal()}
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithSession(WithNotifications(WithTicket(withStyles(styles)(Deliveries))))));