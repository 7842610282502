import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ItemForm from "./EditForm";

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    Checkbox,
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table } from 'components';

//Services
import itemsService from "services/itemsService";
import suppliersService from "services/suppliersService";
import storagesService from "services/storagesService";
import classificationsService from "services/classificationsService";
import ModalComponent from 'components/Modal';

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';

class Items extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        items: [],
        date: Moment().startOf('day').toDate(),
        tab: 0,
        plasticBoxes: "",
        details: [],
        showModal: false,
        id: null,
        unitKeys: [],
        branches: [],
        clasifications: [],
        storages: [],
    };

    columns = [
        {
            title: "ID",
            field: "itemId",
            editable: 'never',
        },
        {
            title: "Nombre",
            field: 'detail'
        },
        {
            title: "Código SAP",
            field: 'sapCode'
        },
        {
            title: "U/M",
            field: 'measurementUnitName'
        },
        {
            title: "Grupo",
            field: 'groupName',
        },
        {
            title: "Código SAP",
            field: 'sapCode',
        },
        {
            title: "Activo",
            field: 'isActive',
            type: 'bool',
            render: row => (
                <Checkbox
                    disabled
                    checked={row.isActive}
                />
            )
        },
    ];

    componentDidMount = () => {
        this.getData();
        this.getCatalogs();
    }

    getData = async () => {
        try {
            const response = await itemsService.getItems();
            if (response.ok && response.data.status != "Fail") {
                this.setState({ items: response.data.data });
            }
        }
        catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
    }

    getCatalogs = async () => {
        try {
            const unitsRes = await itemsService.getMeasurementUnits();
            if (unitsRes.ok && unitsRes.data.status != "Fail") {
                this.setState({
                    units: unitsRes.data.data.map(i => ({
                        value: i.measurementUnitId,
                        label: i.name,
                    }))
                });
            }

            const groupsRes = await itemsService.getGroups();
            if (groupsRes.ok && groupsRes.data.status != "Fail") {
                this.setState({
                    groups: groupsRes.data.data.map(i => ({
                        value: i.businessGroupId,
                        label: i.businessGroupName
                    }))
                });
            }

            const unitKeysRes = await itemsService.getUnitKeys();
            if (unitKeysRes.ok && unitKeysRes.data.status != "Fail") {
                this.setState({
                    unitKeys: unitKeysRes.data.data.map(i => ({
                        value: i.unitKey,
                        label: `${i.unitKey} - ${i.name}`
                    }))
                });
            }

            const branchesRes = await suppliersService.getBranches();
            if (branchesRes.ok && branchesRes.data.status != "Fail") {
                this.setState({
                    branches: branchesRes.data.data
                });
            }

            const clasificationsRes = await classificationsService.getAll();
            if (clasificationsRes.ok && clasificationsRes.data.status != "Fail") {
                this.setState({
                    clasifications: clasificationsRes.data.data.map(i => ({...i, value: i.clasificationId, label: i.name }))
                });
            }

            const storagesRes = await storagesService.getAll();
            if (storagesRes.ok && storagesRes.data.status != "Fail") {
                this.setState({
                    storages: storagesRes.data.data.map(i => ({...i, value: i.storageId, label: i.name }))
                });
            }
        }
        catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    getTitle = () => {
        return "Artículos";
    }

    renderModal = () => {
        const {
            showModal,
            id,
            units,
            groups,
            items,
            unitKeys,
            branches,
            clasifications,
            storages,
        } = this.state;

        if (!branches.length || !clasifications.length || !storages.length) {
            return null;
        }

        return (
            <ModalComponent
                isOpen={showModal}
                title={id ? "Modificar articulo" : "Agregar articulo"}
                onRequestClose={() => this.setState({ showModal: false, id: null })}
                style={{ width: '90%', height: '90%' }}
            >
                <ItemForm
                    key={id || 0}
                    id={id}
                    onSave={() => {
                        this.getData();
                        this.setState({ showModal: false, id: null });
                    }}
                    groups={groups}
                    units={units}
                    items={items}
                    unitKeys={unitKeys}
                    branches={branches}
                    storages={storages}
                    clasifications={clasifications}
                />
            </ModalComponent>
        );
    }

    renderTable = () => {
        const {
            items,
            loading
        } = this.state;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        isLoading={loading}
                        columns={this.columns}
                        items={items}
                        options={{
                            pageSize: 10,
                            search: true
                            // selection: copiyng ? false : true
                        }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: "Editar",
                                onClick: (e, rowData) => this.setState({ showModal: true, id: rowData.itemId })
                            },
                            {
                                icon: 'add',
                                isFreeAction: true,
                                tooltip: "Agregar",
                                onClick: () => this.setState({ showModal: true })
                            }
                        ]}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                        {this.renderModal()}
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default WithNotifications(withStyles(styles)(Items));