import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    InputLabel
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';
import { MTableToolbar } from 'material-table';
import { DatePicker } from "@material-ui/pickers";

//Services
import { WithTicket, WithSession } from "hoc";
import requisitionsService from "services/requisitionsService";
import storagesService from "services/storagesService";
import classificationsService from "services/classificationsService";

//Assets
import styles from './styles';

class PendingRequisitions extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        lunchRooms: [],
        storages: [],
        classifications: [],
        startDate: Moment().startOf('week').toDate(),
        endDate: Moment.utc().endOf('week').toDate(),
        storageId: 1,
        classificationId: 0,
        lunchRoomId: 0
    };

    columns = [
        {
            title: "Folio",
            field: 'folio',
            width: 150,
        },
        {
            title: "Unidad",
            field: 'lunchRoomName'
        },
        {
            title: "Fecha recibo",
            field: 'receivedDate',
            width: 180,
            render: row => {
                return (
                    <span>{row.receivedDate ? Moment.utc(row.receivedDate).format("DD/MM/YYYY HH:mm") : ""}</span>
                );
            }
        },
        {
            title: "Fecha entrega",
            field: 'deliveryDate',
            width: 180,
            render: row => {
                return (
                    <span>{Moment.utc(row.deliveryDate).format("DD/MM/YYYY")}</span>
                );
            }
        },
        {
            title: "Fecha surtido",
            field: 'filledDate',
            width: 180,
            render: row => {
                return (
                    <span>{row.filledDate ? Moment.utc(row.filledDate).format("DD/MM/YYYY HH:mm") : ""}</span>
                );
            }
        },
        {
            title: "Surtir",
            field: '',
            render: (row) => {
                if (this.state.storageId != 0)
                    return (
                        <Link
                            style={{
                                border: '1px solid',
                                padding: '5px',
                                borderRadius: '5px',
                                color: '#53a0e4'
                            }}
                            to={{
                                pathname: `/confirm-requisitions/${row.supplierRequisitionId}/details`,
                                state: {
                                    storageId: this.state.storageId
                                }
                            }}
                        >
                            Surtir Almacen
                        </Link>
                    );

                return (
                    <div>
                        {
                            this.state.storages
                                .filter(x => x.value != 0 && row.activeStorages.includes(x.value))
                                .map((i, index) => (
                                    <Link
                                        style={row.confirmedStorages.includes(i.value)
                                            ? {
                                                marginRight: 25,
                                                border: '1px solid',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                color: 'gray',
                                                pointerEvents: 'none',
                                                cursor: 'not-allowed',
                                                textDecoration: 'line-through'
                                            }
                                            : {
                                                marginRight: 25,
                                                border: '1px solid',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                color: '#53a0e4'
                                            }
                                        }
                                        key={index}
                                        to={{
                                            pathname: `/confirm-requisitions/${row.supplierRequisitionId}/details`,
                                            state: {
                                                storageId: i.value
                                            }
                                        }}
                                    >
                                        {i.label}
                                    </Link>
                                ))
                        }
                    </div>
                );
            }
        }
    ];

    statuses = [
        { value: 1, label: "Inicial" },
        { value: 2, label: "En progreso" },
        { value: 3, label: "Finalizado" }
    ]

    componentDidMount = () => {
        this.getData();
        this.search();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            startDate,
            endDate,
            storageId,
            classificationId
        } = this.state;

        if (prevState.endDate != endDate && endDate < startDate)
            this.setState({ startDate: endDate }, this.search);
        else if (prevState.startDate != startDate && startDate > endDate)
            this.setState({ endDate: startDate }, this.search);
        else if (prevState.startDate != startDate || prevState.endDate != endDate || prevState.storageId != storageId || prevState.classificationId != classificationId)
            this.search();
    }

    getData = async () => {
        const storagesResponse = await storagesService.getAll();
        const classificationsResponse = await classificationsService.getAll();

        if (storagesResponse.ok && storagesResponse.data.status != "Fail") {
            const storages = storagesResponse.data.data.map(i => ({ value: i.storageId, label: i.name }));
            this.setState({ storages: [{ value: 0, label: "TODOS" }, ...storages] });
        }

        if (classificationsResponse.ok && classificationsResponse.data.status != "Fail") {
            const classifications = classificationsResponse.data.data.map(i => ({
                value: i.clasificationId, label: i.name, storageId: i.storageId
            }));
            this.setState({ classifications: [{ value: 0, label: "TODOS" }, ...classifications] });
        }
    }

    search = async () => {
        const {
            startDate,
            endDate,
            classificationId,
            storageId
        } = this.state;

        const model = {
            startDate: Moment.utc(startDate).local(),
            endDate: Moment.utc(endDate).local(),
            storageId: storageId && storageId > 0 ? storageId : null,
            classificationId: classificationId && classificationId > 0 ? classificationId : null
        };

        const response = await requisitionsService.toBeConfirmed(model);
        if (response.ok && Array.isArray(response.data.data)) {
            this.setState({ requisitions: response.data.data }, () => {
                let lunchRooms = [];
                this.state.requisitions.forEach(i => {
                    if (!lunchRooms.some(x => x.value == i.lunchRoomId))
                        lunchRooms.push({ value: i.lunchRoomId, label: i.lunchRoomName });
                });
                this.setState({ lunchRooms });
            });
        }
    }

    getTitle = () => {

        return "Requisiones por surtir";
    }

    renderFilters = () => {
        const {
            lunchRoomId,
            lunchRooms,
            startDate,
            endDate,
            storageId,
            classificationId,
            storages,
            classifications
        } = this.state;

        const {
            classes
        } = this.props;

        let tmpLunchRooms = [...lunchRooms];
        let tmpClassifications = [...classifications];

        if (lunchRooms && lunchRooms.length)
            tmpLunchRooms.unshift({ value: 0, label: "TODOS" });

        if (storageId)
            tmpClassifications = tmpClassifications.filter(x => x.storageId == storageId || x.value == 0);

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha inicial</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={startDate}
                            onChange={startDate => this.setState({ startDate })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha final</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={endDate}
                            onChange={endDate => this.setState({ endDate })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Almacen</InputLabel>
                        <Select
                            value={storageId}
                            onChange={(selected) => {
                                this.setState({ storageId: selected.value });
                            }}
                            options={storages}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Clasificación</InputLabel>
                        <Select
                            key={storageId}
                            value={classificationId}
                            onChange={(selected) => {
                                this.setState({ classificationId: selected.value });
                            }}
                            options={tmpClassifications}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                {
                    tmpLunchRooms && tmpLunchRooms.length > 0 &&
                    <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                        <Grid
                            direction="column"
                            justify="center"
                            className={
                                classNames(
                                    classes.inputRoot
                                )
                            }
                        >
                            <InputLabel>Unidad</InputLabel>
                            <Select
                                value={lunchRoomId}
                                onChange={(selected) => {
                                    this.setState({ lunchRoomId: selected.value });
                                }}
                                options={tmpLunchRooms}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                menuPortalTarget={document.body}
                                menuPosition={'absolute'}
                                menuPlacement={'bottom'}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    }

    renderTable = () => {
        const {
            requisitions,
            selectedItems,
            copiyng,
            lunchRoomId
        } = this.state;

        let items = requisitions;

        if (lunchRoomId) {
            items = items.filter(x => x.lunchRoomId == lunchRoomId);
        }

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            // selection: copiyng ? false : true
                        }}
                        // actions={copiyng ? [] : [
                        //     {
                        //         icon: 'content_copy',
                        //         tooltip: 'Copiar al porta papeles',
                        //         onClick: this.copyRows
                        //     }
                        // ]}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        components={{
                            Toolbar: props => (
                                <div>
                                    {this.renderFilters()}
                                    <MTableToolbar {...props} />
                                </div>
                            ),
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading,
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithSession(WithTicket(withStyles(styles)(PendingRequisitions)))));