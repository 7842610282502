import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';

// UI Components
import {
    withStyles,
    Grid,
    CircularProgress,
    InputLabel,
    Button,
    TextField
} from '@material-ui/core';

import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';
import { MTableToolbar } from 'material-table';

//Services
import { WithTicket, WithSession } from "hoc";
import productsService from "services/productsService";
import routesService from "services/routesService";
import lunchRoomsService from "services/lunchRoomsService";
import requisitionDetailsService from "services/requisitionDetailsService";
import driversService from "services/driversService";

//Assets
import styles from './styles';
import { WithNotifications } from 'hoc';
import { SelectAsync } from 'components';

class LunchRoomsInfo extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        routes: [],
        lunchRooms: [],
        date: Moment().startOf('day').toDate(),
        routeId: null,
        lunchRoom: null,
        // date: Moment('18/04/2020', "DD/MM/YYYY").startOf('day').toDate(),
        // routeId: 6,
        // lunchRoom: { lunchRoomName: "FAMA", lunchRoomId: 8 },
        tab: 0,
        plasticBoxes: "",
        details: []
    };

    columns = [
        {
            title: "Unidad",
            field: 'lunchRoomName'
        },
        {
            title: "Identificador",
            field: 'identifier',
            render: (row) => (
                <TextField
                    value={row.identifier}
                    onChange={e => {
                        let lunchRooms = this.state.lunchRooms;
                        let item = lunchRooms[row.tableData.id];
                        item.identifier = e.target.value;
                        this.setState({ lunchRooms });
                    }}
                />
            )
        },
        {
            title: "Distancia desde AUPSA",
            field: 'distanceFromInitial',
            render: (row) => (
                <TextField
                    value={row.distanceFromInitial}
                    onChange={e => {
                        let lunchRooms = this.state.lunchRooms;
                        let item = lunchRooms[row.tableData.id];
                        item.distanceFromInitial = e.target.value;
                        this.setState({ lunchRooms });
                    }}
                />
            )
        },
        {
            title: "Distancia desde comedor anterior",
            field: 'distanceFromPrevious',
            render: (row) => (
                <TextField
                    value={row.distanceFromPrevious}
                    onChange={e => {
                        let lunchRooms = this.state.lunchRooms;
                        let item = lunchRooms[row.tableData.id];
                        item.distanceFromPrevious = e.target.value;
                        this.setState({ lunchRooms });
                    }}
                />
            )
        }
    ];

    componentDidMount = () => {
        this.getData();
        this.search();

        if (this.state.lunchRoom)
            this.getLunchRooms();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            date,
            routeId,
        } = this.state;

        if (prevState.date != date || prevState.routeId != routeId)
            this.search();

        if (!prevState.lunchRoom && this.state.lunchRoom)
            this.getLunchRooms();
    }

    getData = async () => {
        const routesResponse = await routesService.getAll();
        if (routesResponse.ok && routesResponse.data.status != "Fail") {
            const routes = routesResponse.data.data.map(i => ({ value: i.routeId, label: i.name }));
            this.setState({ routes, routeId: routes[0].value });
        }
    }

    getLunchRooms = async () => {
        const {
            lunchRoom,
            date
        } = this.state;

        const response = await requisitionDetailsService.getRouteLunchRoomDetails(lunchRoom.lunchRoomId, date);

        if (!response.ok) {
            this.props.addNotification({ message: response.data.data.error, options: { variant: "error" } });
            return;
        }

        this.setState({
            details: response.data.data.map((i, index) => ({ ...i, index }))
        });
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            details
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    search = async () => {
        const {
            routeId
        } = this.state;

        this.setState({ loading: true });

        const response = await lunchRoomsService.search(routeId, new Date());
        if (response.ok && Array.isArray(response.data.data)) {
            this.setState({ lunchRooms: response.data.data });
        }

        this.setState({ loading: false });
    }

    getTitle = () => {

        return "Informacion de Unidades";
    }

    saveReturn = async () => {
        const {
            plasticBoxes,
            details,
            lunchRoom,
            date
        } = this.state;

        if (plasticBoxes == "" || isNaN(plasticBoxes)) {
            this.props.addNotification({ message: "Número de cajas plasticas requerido, al menos 0", options: { variant: "error" } });
            return;
        }

        if (this.saving)
            return;

        this.saving = true;
        this.setState({ saving: true });

        const model = {
            lunchRoomId: lunchRoom.lunchRoomId,
            plasticBoxes,
            date,
            quantities: details.map(i => ({
                itemPresentationId: i.itemPresentationId,
                quantity: i.quantity
            }))
        };

        const response = await driversService.postReturn(model).catch(() => {
            this.saving = false;
            this.setState({ saving: false });
        });

        this.saving = false;
        this.setState({ saving: false });

        if (!response.ok || !response.data.data) {
            this.props.addNotification({ message: "Error validando unidad", options: { variant: "error" } });
            return;
        }

        this.props.addNotification({ message: "Devolucion registrada", options: { variant: "success" } });
        const lunchRooms = this.state.lunchRooms;
        const currentLunchRoom = lunchRooms.find(x => x.lunchRoomId == this.state.lunchRoom.lunchRoomId);
        currentLunchRoom.returnStatus = "Devolucion registrada";
        this.setState({ lunchRoom: null, plasticBoxes: "", details: [], lunchRooms });
    }

    renderFilters = () => {
        const {
            routeId,
            routes
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Ruta</InputLabel>
                        <Select
                            value={routeId}
                            onChange={(selected) => {
                                this.setState({ routeId: selected.value });
                            }}
                            options={routes}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    openAddItemModal = () => {

        this.setState({ showItemsModal: true });
    }

    save = async () => {
        const model = {
            lunchRooms: this.state.lunchRooms.map(i => ({ ...i, lunchRoomId: i.lunchRoomId, identifier: i.identifier }))
        };

        const response = await lunchRoomsService.saveLunchRoomsInfo(model);

        if (!response.ok) {
            this.props.addNotification({ message: 'No se pudo guardar información', options: { variant: "error" } });
        }

        this.props.addNotification({ message: 'Información guardada', options: { variant: "success" } });
    }

    renderTable = () => {
        const {
            lunchRooms,
            selectedItems,
            copiyng,
            loading
        } = this.state;

        let items = lunchRooms;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    <Table
                        isLoading={loading}
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            search: false
                            // selection: copiyng ? false : true
                        }}
                        actions={items && items.length ? [
                            {
                                icon: 'save',
                                isFreeAction: true,
                                tooltip: 'Guardar',
                                onClick: this.save
                            }
                        ] : []}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        components={{
                            Toolbar: props => (
                                <div>
                                    {this.renderFilters()}
                                    <MTableToolbar {...props} />
                                </div>
                            ),
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderAddItemModal = () => {
        const {
            itemId,
            quantity
        } = this.state;

        return (
            <Modal
                title="Agregar artículo"
                isOpen={this.state.showItemsModal}
                onRequestClose={() => {
                    this.setState({ showItemsModal: false });
                }}
                style={{ width: '350px', height: '343px' }}
            >
                <Grid container style={{ padding: 10, height: 300 }} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <InputLabel>Articulo</InputLabel>
                        <SelectAsync
                            value={itemId}
                            onChange={(selected) => this.setState({ itemId: selected.value, item: selected })}
                            cacheOptions
                            loadOptions={async value => {
                                if (!value) return [];
                                const res = await productsService.search(value);

                                const data = res.data && res.data.data && Array.isArray(res.data.data)
                                    ? res.data.data
                                    : [];

                                return data.map(i => ({
                                    value: i.itemPresentationId,
                                    label: i.itemDetail == i.itemPresentationDescription
                                        ? i.itemDetail
                                        : i.itemDetail + " ** " + i.itemPresentationDescription,
                                    storageName: i.storageName,
                                    measurementUnitName: i.measurementUnitName
                                }));
                            }}
                            onInputChange={newValue => {
                                this.setState({ articuloSearch: newValue });
                                return newValue;
                            }}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Cantidad</InputLabel>
                        <TextField
                            value={quantity}
                            onChange={(e) => this.setState({ quantity: e.target.value })}
                            fullWidth
                        />
                    </Grid>
                    <Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.addItem}
                        >
                            Agregar
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isLoading
                                        ? <CircularProgress />
                                        : this.renderTable()
                                }
                            </PortletContent>
                        </Portlet>
                        {this.renderAddItemModal()}
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithSession(WithNotifications(WithTicket(withStyles(styles)(LunchRoomsInfo))))));