import Api from "./Api";

class CityService extends Api {
    constructor(controller){
        super(controller);
    }

    save = model => {
		return this.API.post(`${this.controller}`, model);
	};
}

let service = new CityService("storages");

export default service;