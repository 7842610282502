import Api from "./Api";

class HistoricalPriceService extends Api {

    constructor(controller) {
        super(controller);
    }

    getClientGroups = (clientId) => {
        return this.API.get(`${this.controller}/client/${clientId}/clientGroups`);
    };

    getAllClientSaleItems = (clientId) => {
        return this.API.get(`${this.controller}/client/${clientId}/saleItems`);
    };

    getClientSaleItemsWithoutGroup = (clientId) => {
        return this.API.get(`${this.controller}/client/${clientId}/saleItems/withoutGroup`);
    };

    getClientGroupSaleItems = (clientGroupId) => {
        return this.API.get(`${this.controller}/clientgroup/${clientGroupId}/saleItems`);
    };

    getClientSaleItemHistoricalPrice = (clientId, saleItemId) => {
        return this.API.get(`${this.controller}/client/${clientId}/saleitem/${saleItemId}`);
    };
}

let service = new HistoricalPriceService("historical-price");

export default service;